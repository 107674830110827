"use client"

import React from "react"


import { cn } from "@/utils/utils"
import {
  Expand,
  Image as ImageIcon,
  X,
  Download,
  RefreshCw,
} from "lucide-react"

import { Dialog, DialogContent } from "@/components/ui/dialog"
import Image from "next/image"
import { getFileIcon } from "@/app/[lang]/app/(task)/(components)/AttachmentsSection"
import { useTaskAttachments } from "@/hooks/useTaskAttachments"
import { Button } from "@/components/ui/button"
import { Skeleton } from "@/components/ui/skeleton"

export function sortedAttachments(attachments: { name: string, url: string }[]) {
  return [...attachments].sort((a, b) => {
    const extensionA = a.name.split(".").pop()?.toLowerCase() || "";
    const extensionB = b.name.split(".").pop()?.toLowerCase() || "";

    const isImageA = ["png", "jpg", "jpeg"].includes(extensionA);
    const isImageB = ["png", "jpg", "jpeg"].includes(extensionB);

    if (isImageA && !isImageB) return -1;
    if (!isImageA && isImageB) return 1;
    return a.name.localeCompare(b.name); // Sort alphabetically within each group
  });
}

export function SheetTaskAttachments({ taskId }: { taskId: string }) {
  const { attachments, isLoading: isLoadingAttachments, error: errorAttachments, refetch } = useTaskAttachments(taskId)

  const [selectedImage, setSelectedImage] = React.useState<{ name: string, url: string } | null>(null)
  const [imageOrientation, setImageOrientation] = React.useState<Record<string, 'portrait' | 'landscape'>>({})
  const [isImageLoading, setIsImageLoading] = React.useState(false)
  const [loadingImages, setLoadingImages] = React.useState<Record<string, boolean>>({})

  // Function to determine image orientation
  const checkImageOrientation = (url: string, name: string) => {
    const img = new window.Image()
    img.onload = () => {
      const isPortrait = img.height > img.width
      setImageOrientation(prev => ({
        ...prev,
        [name]: isPortrait ? 'portrait' : 'landscape'
      }))
    }
    img.src = url
  }

  // Function to handle file download
  const handleDownload = (url: string, filename: string) => async (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    try {
      // Show loading indicator or feedback here if needed

      // Fetch the file
      const response = await fetch(url)

      if (!response.ok) {
        throw new Error(`Failed to download: ${response.status} ${response.statusText}`)
      }

      // Convert to blob
      const blob = await response.blob()

      // Create object URL
      const objectUrl = URL.createObjectURL(blob)

      // Create download link
      const link = document.createElement('a')
      link.href = objectUrl
      link.download = filename
      link.style.display = 'none'

      // Trigger download
      document.body.appendChild(link)
      link.click()

      // Cleanup
      document.body.removeChild(link)
      setTimeout(() => URL.revokeObjectURL(objectUrl), 100)
    } catch (error) {
      console.error('Download failed:', error)
      // Show error feedback to user if needed
    }
  }

  // Check orientation for all images when component mounts
  React.useEffect(() => {
    // Initialize loading state for all images
    const initialLoadingState: Record<string, boolean> = {}

    attachments.forEach(attachment => {
      const extension = attachment.name.split(".").pop()?.toLowerCase()
      if (extension === "png" || extension === "jpg" || extension === "jpeg") {
        checkImageOrientation(attachment.url, attachment.name)
        initialLoadingState[attachment.name] = true
      }
    })

    setLoadingImages(initialLoadingState)
  }, [attachments])

  // Set loading state when an image is selected
  React.useEffect(() => {
    if (selectedImage) {
      setIsImageLoading(true)
    }
  }, [selectedImage])

  // Handle image load completion
  const handleImageLoaded = (name: string) => {
    setLoadingImages(prev => ({
      ...prev,
      [name]: false
    }))
  }

  if (errorAttachments) {
    return (
      <div className="flex flex-col space-y-2">
        <div className="text-sm text-destructive">
          Une erreur est survenue lors du chargement des pièces jointes.
        </div>
        <Button variant="outline" size="sm" onClick={() => refetch()}>
          <RefreshCw className="h-4 w-4 mr-2" />
          Réessayer
        </Button>
      </div>
    )
  }

  if (isLoadingAttachments) {
    return (
      <div className="flex flex-col gap-2">
        <div className="text-400-12 text-black-dark">Pièces jointes</div>
        <div className="space-y-2">
          <Skeleton className="h-10 w-full" />
          <Skeleton className="h-10 w-full" />
          <Skeleton className="h-10 w-full" />
        </div>
      </div>
    )
  }


  if (!attachments || attachments.length === 0) {
    return (
      <div className="flex flex-col gap-2">
        <div className="text-400-12 text-black-dark">Pièces jointes</div>
        <div className="text-[#A1A1AA] text-400-12">Pas de pièces jointes</div>
      </div>
    )
  }



  return (
    <div className="flex flex-col gap-2">
      <div className="text-400-12 text-black-dark">Pièces jointes</div>
      <div className="grid grid-cols-1 gap-4">
        {sortedAttachments(attachments).map((attachment) => {
          const extension = attachment.name.split(".").pop()?.toLowerCase()

          if (extension === "png" || extension === "jpg" || extension === "jpeg") {
            const orientation = imageOrientation[attachment.name] || 'landscape'
            const isLoading = loadingImages[attachment.name]

            return (
              <div key={attachment.name} className="group flex flex-col gap-2 border border-gray-200 rounded-md  hover:border-forecast-blue-light overflow-hidden">
                <div className={cn(
                  "relative overflow-hidden w-full",
                  "group-hover:bg-gray-100",
                  orientation === 'portrait' ? "flex justify-center h-[320px]" : "h-[240px]"
                )}>
                  {isLoading && (
                    <div className="absolute inset-0 bg-gray-100 animate-pulse flex items-center justify-center">
                      <ImageIcon className="h-10 w-10 text-gray-300" />
                    </div>
                  )}
                  <Image
                    src={attachment.url}
                    alt={attachment.name}
                    className={cn(
                      "object-contain transition-opacity duration-300",
                      isLoading ? "opacity-0" : "opacity-100"
                    )}
                    fill
                    sizes="(max-width: 768px) 100vw, 50vw"
                    style={{
                      objectFit: 'contain',
                      width: '100%',
                      height: orientation === 'portrait' ? 'auto' : '100%'
                    }}
                    onLoad={() => handleImageLoaded(attachment.name)}
                  />
                  <button
                    className="absolute top-2 right-2 bg-white/80 rounded-full p-1.5 hover:bg-white transition-colors z-10"
                    onClick={() => setSelectedImage(attachment)}
                  >
                    <Expand className="h-6 w-6 text-gray-700" />
                  </button>
                </div>
                <div className="flex items-center gap-2 text-400-14 p-2">
                  {getFileIcon(attachment.name)}
                  <span className="truncate flex-1">
                    {attachment.name}
                  </span>
                  <button
                    className="p-1.5 rounded-full hover:bg-gray-100 transition-colors"
                    title="Télécharger"
                    onClick={handleDownload(attachment.url, attachment.name)}
                  >
                    <Download className="h-6 w-6 text-gray-600" />
                  </button>
                </div>
              </div>
            )
          }

          return (
            <div key={attachment.name} className="group flex flex-col gap-2 border border-gray-200 rounded-md p-3 hover:border-forecast-blue-light">
              <div className="flex items-center gap-2 text-400-14">
                {getFileIcon(attachment.name)}
                <span className="truncate flex-1">
                  {attachment.name}
                </span>
                <button
                  className="p-1.5 rounded-full hover:bg-gray-100 transition-colors"
                  title="Télécharger"
                  onClick={handleDownload(attachment.url, attachment.name)}
                >
                  <Download className="h-6 w-6 text-gray-600" />
                </button>
              </div>
            </div>
          )
        })}
      </div>

      {/* Image Modal */}
      <Dialog open={!!selectedImage} onOpenChange={(open) => !open && setSelectedImage(null)}>
        <DialogContent className="max-w-5xl w-full p-0 overflow-hidden bg-transparent border-none">
          <div className="relative bg-black/90 w-full h-full flex items-center justify-center">
            <button
              className="absolute top-4 right-4 bg-white/20 rounded-full p-1.5 hover:bg-white/40 transition-colors z-10"
              onClick={() => setSelectedImage(null)}
            >
              <X className="h-5 w-5 text-white" />
            </button>

            {selectedImage && (
              <div className="w-full h-full max-h-[85vh] flex items-center justify-center p-4">
                {isImageLoading && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="h-8 w-8 rounded-full border-4 border-white/20 border-t-white animate-spin"></div>
                  </div>
                )}
                <Image
                  src={selectedImage.url}
                  alt={selectedImage.name}
                  className={cn(
                    "object-contain max-h-full max-w-full transition-opacity duration-300",
                    isImageLoading ? "opacity-0" : "opacity-100"
                  )}
                  width={1600}
                  height={1000}
                  priority
                  onLoad={() => setIsImageLoading(false)}
                />
              </div>
            )}

            <div className="absolute bottom-4 left-0 right-0 text-center text-white bg-black/50 py-2 px-4">
              {selectedImage?.name}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

