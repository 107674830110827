/* eslint-disable react/jsx-max-depth */
import React, { useCallback, useRef, useState, useEffect } from "react"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Trans } from "@lingui/macro"
import { FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { PaperclipPlus } from "mdi-material-ui"
import { EmptyBlock } from "@/components/ui-custom/empty-block"
import { CustomIcon } from "@/components/ui-custom/custom-icon"
import { useToast } from "@/components/ui/use-toast"
import { ExtendedTask } from "../types"
import {
  Trash2,
  X,
  Download,
  FileImage,
  FileType,
  FileText,
  FileSpreadsheet,
  FileArchive,
  FileCode,
  FileAudio,
  FileVideo,
  File,
  ExternalLink,
  Eye
} from "lucide-react"
import Image from "next/image"
import { cn } from "@/utils/utils"
import { sortedAttachments } from "@/components/ui-custom/dialogs/sheet-task-attachments"


// Get appropriate icon based on file extension
export const getFileIcon = (filename: string) => {
  const extension = filename.split(".").pop()?.toLowerCase() || ""

  // Image files
  if (["png", "jpg", "jpeg", "gif", "webp", "svg", "bmp", "tiff"].includes(extension)) {
    return <FileImage className="h-6 w-6 text-black-light" />
  }

  // PDF files
  if (extension === "pdf") {
    return <FileType className="h-6 w-6 text-emergency-level-1" />
  }

  // Document files
  if (["doc", "docx", "txt", "rtf", "odt"].includes(extension)) {
    return <FileText className="h-6 w-6 text-blue-600" />
  }

  // Spreadsheet files
  if (["xls", "xlsx", "csv", "ods"].includes(extension)) {
    return <FileSpreadsheet className="h-6 w-6 text-water-green" />
  }

  // Archive files
  if (["zip", "rar", "7z", "tar", "gz"].includes(extension)) {
    return <FileArchive className="h-6 w-6 text-amber-600" />
  }

  // Code files
  if (["js", "ts", "html", "css", "php", "py", "java", "c", "cpp", "json", "xml"].includes(extension)) {
    return <FileCode className="h-6 w-6 text-violet-600" />
  }

  // Audio files
  if (["mp3", "wav", "ogg", "flac", "aac"].includes(extension)) {
    return <FileAudio className="h-6 w-6 text-pink-500" />
  }

  // Video files
  if (["mp4", "avi", "mov", "wmv", "mkv", "webm"].includes(extension)) {
    return <FileVideo className="h-6 w-6 text-purple-500" />
  }

  // Default file icon for unknown types
  return <File className="h-6 w-6 text-gray-500" />
}


type FileWithURL = File & { url?: string }
interface AttachmentsSectionProps {
  task?: Pick<ExtendedTask, "status" | "attachments">
  emptyText?: string
  title?: React.ReactNode
  onConfirm: (files: { file: File }[]) => void
  onDelete: (indexList: number[]) => void
  files: { file: FileWithURL }[]
}

export function AttachmentsSection({
  task,
  emptyText,
  title,
  onConfirm,
  onDelete,
  files,
}: AttachmentsSectionProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [filesToUpload, setFilesToUpload] = useState<File[]>([])
  const { toast } = useToast()

  // Image preview states
  const [selectedImage, setSelectedImage] = useState<{ name: string, url: string } | null>(null)
  const [isImageLoading, setIsImageLoading] = useState(false)

  // Refs for the file input and drop zone elements.
  const attachmentsInputRef = useRef<HTMLInputElement>(null)
  const dropZoneRef = useRef<HTMLDivElement>(null)

  // Set loading state when an image is selected
  useEffect(() => {
    if (selectedImage) {
      setIsImageLoading(true)
    }
  }, [selectedImage])

  // Handler for files dropped into the drop zone.
  const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const droppedFiles = Array.from(e.dataTransfer.files)
    setFilesToUpload((prev) => [...prev, ...droppedFiles])
    if (dropZoneRef.current) {
      dropZoneRef.current.style.border = ""
      dropZoneRef.current.classList.add("border-spaced-dotted")
    }
  }, [])

  // Handler for drag over.
  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (dropZoneRef.current) {
      dropZoneRef.current.classList.remove("border-spaced-dotted")
      dropZoneRef.current.style.border = "2px dashed rgb(0, 82, 153)"
    }
  }, [])

  // Handler for when dragging leaves the drop zone.
  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (dropZoneRef.current) {
      dropZoneRef.current.style.border = ""
      dropZoneRef.current.classList.add("border-spaced-dotted")
    }
  }, [])

  // Handler for selecting files via the file input.
  const handleFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files
    if (selectedFiles && selectedFiles.length > 0) {
      setFilesToUpload((prev) => [...prev, ...Array.from(selectedFiles)])
    }
  }, [])

  // Handler to confirm the pending file uploads.
  const handleConfirmFiles = useCallback(
    (_: React.MouseEvent<HTMLButtonElement>) => {
      if (filesToUpload.length === 0) {
        toast({ description: "Aucun fichier a été ajouté!", variant: "destructive" })
        return
      }
      const uploadedFiles = filesToUpload.map((file) => ({ file }))
      setFilesToUpload([])
      setIsDialogOpen(false)
      onConfirm(uploadedFiles)
      toast({ description: "Les fichiers ont été enregistrés" })
    },
    [filesToUpload, onConfirm, toast],
  )

  // Remove a file from the pending upload list.
  const removeFileFromUpload = useCallback((index: number) => {
    setFilesToUpload((prev) => prev.filter((_, i) => i !== index))
  }, [])

  // Function to handle file download
  const handleDownload = useCallback((url: string, filename: string) => async (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    try {
      // Fetch the file
      const response = await fetch(url)

      if (!response.ok) {
        throw new Error(`Failed to download: ${response.status} ${response.statusText}`)
      }

      // Convert to blob
      const blob = await response.blob()

      // Create object URL
      const objectUrl = URL.createObjectURL(blob)

      // Create download link
      const link = document.createElement('a')
      link.href = objectUrl
      link.download = filename
      link.style.display = 'none'

      // Trigger download
      document.body.appendChild(link)
      link.click()

      // Cleanup
      document.body.removeChild(link)
      setTimeout(() => URL.revokeObjectURL(objectUrl), 100)
    } catch (error) {
      console.error('Download failed:', error)
      toast({
        description: "Échec du téléchargement du fichier",
        variant: "destructive"
      })
    }
  }, [toast])

  // Determine if the task is closed.
  const isTaskClosed = task?.status === "closed"
  // Files that have already been attached.
  const attachedFiles = sortedAttachments(files.map((file) => ({ name: file.file.name, url: file.file.url || "" })))

  // Check if a file is an image
  const isImageFile = (filename: string) => {
    const extension = filename.split(".").pop()?.toLowerCase()
    return extension === "png" || extension === "jpg" || extension === "jpeg"
  }



  return (
    <div>
      {title || (
        <div className="font-din title-700-22 mb-10">
          <Trans>Fichiers joints</Trans>
        </div>
      )}

      {attachedFiles.length === 0 ? (
        <EmptyBlock>
          {emptyText || <Trans>Aucun fichier n&apos;a été joint à cette tâche !</Trans>}
        </EmptyBlock>
      ) : (
        <div className="flex flex-col gap-3">
          {attachedFiles.map((attachment, index) => (
            <div key={index} className="h-10 flex items-center gap-2 group hover:bg-gray-200 px-1">
              <div className="flex items-center group-hover:hidden">
                {getFileIcon(attachment.name)}
              </div>
              {isImageFile(attachment.name) ? (
                <Eye
                  className="hidden group-hover:block h-6 w-6 cursor-pointer hover:text-black"
                  onClick={() => {
                    setSelectedImage({
                      name: attachment.name,
                      url: attachment.url as string
                    })
                  }}
                />
              ) : (
                <ExternalLink
                  className="hidden group-hover:block h-6 w-6 cursor-pointer hover:text-black"
                  onClick={() => {
                    window.open(attachment.url, "_blank")
                  }}
                />
              )}
              <div className="text-400-16">{attachment.name}</div>
              <div className="flex-1" />
              <div className="flex gap-6 justify-end">
                {!isTaskClosed && (
                  <>
                    <Download

                      className="hidden group-hover:block h-6 w-6 cursor-pointer hover:text-black"
                      onClick={handleDownload(
                        attachment.url,
                        attachment.name
                      )}
                    />
                    <Trash2
                      className="h-6 w-6 cursor-pointer hover:text-warning-text"
                      onClick={() => {
                        onDelete([index])
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {!isTaskClosed && (
        <div className="flex justify-end gap-4 pt-4 mt-5">
          {attachedFiles.length > 0 && (
            <Button
              variant="forecastGhost"
              type="button"
              onClick={() => {
                // Clearing pending files.
                onDelete(attachedFiles.map((_, index) => index))
              }}
            >
              <Trans>Supprimer tous les fichiers</Trans>
            </Button>
          )}
          <FormField
            name="attachments"
            render={() => (
              <FormItem className="space-y-0">
                <FormControl>
                  <>
                    <Input
                      type="file"
                      className="hidden"
                      ref={attachmentsInputRef}
                      multiple
                      accept="image/*, application/pdf"
                      onChange={handleFileChange}
                    />

                    <Dialog open={isDialogOpen}>
                      <Button
                        type="button"
                        variant="forecastOutline"
                        onClick={() => setIsDialogOpen(true)}
                      >
                        <PaperclipPlus
                          style={{ height: 16, width: 16 }}
                          className="mr-1"
                        />
                        <Trans>Joindre des fichiers</Trans>
                      </Button>
                      <DialogContent className="max-w-[768px]">
                        <DialogHeader>
                          <DialogTitle className="text-700-24">
                            <Trans>Téléchargez des fichiers</Trans>
                          </DialogTitle>
                        </DialogHeader>
                        <div
                          ref={dropZoneRef}
                          onDrop={handleDrop}
                          onDragOver={handleDragOver}
                          onDragLeave={handleDragLeave}
                          className="h-[120px] flex items-center border-spaced-dotted px-6 py-8"
                        >
                          <div className="h-14 flex justify-center items-center">
                            <CustomIcon type="Icon-upload" className="!h-8 !w-8 mr-6" />
                            <div className="flex flex-col justify-center">
                              <div className="flex items-baseline">
                                <span>Déposer le fichier ici ou</span>
                                <span
                                  onClick={() => attachmentsInputRef.current?.click()}
                                  className="cursor-pointer text-600-14 text-forecast-blue-readable-light px-4 py-3"
                                >
                                  Parcourir sur l&apos;ordinateur
                                </span>
                              </div>
                              <div className="text-500-12 text-black-light">
                                Jpeg, png, docx, xls ou pdf uniquement - 8 Mo maximum
                              </div>
                            </div>
                          </div>
                        </div>
                        {filesToUpload.length > 0 && (
                          <div className="pt-8">
                            <div className="text-500-16 mb-2.5">
                              <Trans>Liste des fichiers à ajouter</Trans>
                            </div>
                            <div className="text-400-16">
                              {filesToUpload.map((file, index) => (
                                <div key={index} className="h-10 flex items-center gap-2">
                                  <div className="w-full flex items-center">
                                    {getFileIcon(file.name)}
                                    <span className="ml-2">{file.name}</span>
                                    <div className="flex-1" />
                                    <Trash2
                                      className="h-6 w-6 cursor-pointer"
                                      onClick={() => removeFileFromUpload(index)}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        <DialogFooter className="gap-6">
                          <Button
                            type="button"
                            variant="forecastOutline"
                            onClick={() => {
                              setFilesToUpload([])
                              setIsDialogOpen(false)
                            }}
                          >
                            <Trans>Annuler</Trans>
                          </Button>
                          <Button
                            type="button"
                            variant="forecast"
                            onClick={handleConfirmFiles}
                            disabled={filesToUpload.length === 0}
                          >
                            <Trans>Confirmer</Trans>
                          </Button>
                        </DialogFooter>
                      </DialogContent>
                    </Dialog>
                  </>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      )}

      {/* Image Preview Dialog */}
      <Dialog open={!!selectedImage} onOpenChange={(open) => !open && setSelectedImage(null)}>
        <DialogContent className="max-w-5xl w-full p-0 overflow-hidden bg-transparent border-none">
          <div className="relative bg-black/90 w-full h-full flex items-center justify-center">
            <button
              className="absolute top-4 right-4 bg-white/20 rounded-full p-1.5 hover:bg-white/40 transition-colors z-10"
              onClick={() => setSelectedImage(null)}
            >
              <X className="h-5 w-5 text-white" />
            </button>

            {selectedImage && (
              <div className="w-full h-full max-h-[85vh] flex items-center justify-center p-4">
                {isImageLoading && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="h-8 w-8 rounded-full border-4 border-white/20 border-t-white animate-spin"></div>
                  </div>
                )}
                <Image
                  src={selectedImage.url}
                  alt={selectedImage.name}
                  className={cn(
                    "object-contain max-h-full max-w-full transition-opacity duration-300",
                    isImageLoading ? "opacity-0" : "opacity-100"
                  )}
                  width={1600}
                  height={1000}
                  priority
                  onLoad={() => setIsImageLoading(false)}
                />
              </div>
            )}

            <div className="absolute bottom-4 left-0 right-0 text-center text-white bg-black/50 py-2 px-4">
              {selectedImage?.name}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
