//@ts-nocheck

/* eslint-disable react/jsx-max-depth */
/* eslint-disable complexity */
import { Button } from "@/components/ui/button"
import React from "react"
import { Trans } from "@lingui/macro"

import { useToast } from "@/components/ui/use-toast"

import { closeTask, tryCloseTask } from "@/services/services"

import { CustomIcon } from "@/components/ui-custom/custom-icon"
import { showPageDialog } from "@/components/ui-custom/dialogs/page-dialog"
import { DropdownMenuItem } from "@/components/ui/dropdown-menu"
import { DashboardTask } from "@/services/SupabaseTravoApi"
import { reopenTask } from "@/services/reopenTask"
import { ExtendedTask } from "../types"

export function CloseButton({
  task,
  onCloseDone,
  mode = "Button",
}: {
  task: DashboardTask | ExtendedTask | undefined
  onCloseDone?: () => void
  mode?: "Button" | "DropdownMenuItem"
}) {
  const { toast } = useToast()

  if (task === undefined) {
    return
  }

  async function onCloseTask() {
    if (task === undefined) {
      console.error("onCloseSubTask", "the task is undefined")
      return
    }

    const itemEntity = task.parentId ? "sous-tâche" : "tâche"

    try {
      const result = await tryCloseTask(task.id)
      if (result === true) {
        showPageDialog({
          isOpen: true,
          title: `Clôturer la ${itemEntity} ?`,
          mainMessage: `La ${itemEntity} pourra être consultée mais plus modifiée. Vous pourrez réouvrir la ${itemEntity} par après si vous le souhaitez.`,
          onClose: () => { },
          onCloseText: "Fermer",
          type: "normal",
          onConfirm: async () => {
            await closeTask(task.id)
            if (onCloseDone) {
              onCloseDone()
            } else {
              toast({
                description: `La ${itemEntity} a été clôturée`,
              })
            }
          },
          onConfirmText: "Clôturer",
        })
      }
      // @ts-ignore
      else if (result?.errors) {
        console.error(result?.errors)

        const hasErrorLocation = result?.errors?.some((e) => e.message.includes("lieu"))
        const hasErrorAssignation = result?.errors?.some((e) =>
          e.message.includes("travailleur assigné"),
        )

        const text = {
          title: `Clôture de la ${itemEntity} impossible`,
          description: (
            <div className="mb-4">Pour être clôturée la {itemEntity} doit comporter:</div>
          ),
        }

        // @ts-ignore
        showPageDialog({
          isOpen: true,
          title: text.title,
          mainMessage: "",
          onClose: () => { },
          onConfirmText: "",
          onCloseText: "Fermer",
          type: "error",
          onConfirm: null,
          dialogDescription: (
            <>
              {text.description}
              <div className="flex flex-col gap-2.5">
                <div className=" flex items-center gap-2">
                  <CustomIcon
                    className={hasErrorLocation ? "text-error-text" : "text-success-text"}
                    type={
                      hasErrorLocation
                        ? "Icon-unchecked-done-success"
                        : "Icon-check-done-success"
                    }
                  />
                  <div>Un lieu de prestation</div>
                </div>
                <div className=" flex items-start gap-2">
                  <CustomIcon
                    className={
                      hasErrorAssignation ? "text-error-text" : "text-success-text"
                    }
                    type={
                      hasErrorAssignation
                        ? "Icon-unchecked-done-success"
                        : "Icon-check-done-success"
                    }
                  />
                  <div>
                    Au minimum un travailleur assigné avec une date et des heures de
                    prestation
                  </div>
                </div>
              </div>
            </>
          ),
        })
      }
    } catch (error) {
      console.error(error)
      toast({
        description: "Une erreur est survenue durant la côture!",
        variant: "destructive",
      })
    }
  }

  async function onReopenTask() {
    // TODO: implement reopen task
    if (task === undefined) {
      console.error("onReopenTask", "the task is undefined")
      return
    }
    try {
      await reopenTask(task.id)
      toast({
        description: task.parentId
          ? "La sous-tâche a été réouverte"
          : "La tâche a été réouverte",
      })
    } catch (error) {
      console.error("onReopenTask", error)
      toast({
        description: "Une erreur est survenue durant la réouverture!",
        variant: "destructive",
      })
    }
  }

  if (mode === "DropdownMenuItem") {
    return (
      <DropdownMenuItem onClick={() => (task.status === "closed" ? onReopenTask() : onCloseTask())}>
        <div className="flex items-center gap-1.5">
          <CustomIcon type="Icon-satus-closed" className="" />
          <span className="text-500-14">{task.status === "closed" ? "Réouvrir" : "Clôturer"}</span>
        </div>
      </DropdownMenuItem>
    )
  }

  //TODO : La tâche parente sera réouverte également.

  return (
    <>
      <div className="flex gap-2">
        {task.status === "open" && (
          <Button variant="forecastOutline" type="button" onClick={() => onCloseTask()}>
            <CustomIcon type="Icon-satus-open-blue" className="mr-2" />
            <Trans>Clôturer</Trans>
          </Button>
        )}
        {task.status === "closed" && (
          <Button
            variant="forecastOutline"
            type="button"
            onClick={() => {
              showPageDialog({
                isOpen: true,
                title:
                  task.parentId !== null
                    ? "Réouvrir la sous-tâche ?"
                    : "Réouvrir la tâche ?",
                mainMessage: "",
                onClose: () => { },
                onConfirmText: "Réouvrir",
                onCloseText: "Fermer",
                type: "error",
                onConfirm: onReopenTask,
                dialogDescription:
                  task.parentId !== null ? (
                    <div>
                      La sous-tâche sera modifiable à nouveau. Elle retrouvera l&apos;état
                      exact qu&apos;elle avait juste avant sa clôture.
                    </div>
                  ) : (
                    <>
                      La tâche sera modifiable à nouveau. Elle retrouvera l&apos;état
                      exact qu&apos;elle avait juste avant sa clôture.
                    </>
                  ),
              })
            }}
          >
            <CustomIcon type="Icon-satus-open-blue" className="mr-2" />
            <Trans>Réouvrir</Trans>
          </Button>
        )}
      </div>
    </>
  )
}
