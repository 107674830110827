import { useState, useEffect } from "react"

interface Attachment {
    name: string
    url: string
    size?: number
    createdAt?: string
    mimeType?: string
    error?: string
}

interface UseTaskAttachmentsResult {
    attachments: Attachment[]
    isLoading: boolean
    error: Error | null
    refetch: () => Promise<void>
}

/**
 * Hook to fetch task attachments on demand
 * @param taskId The ID of the task to fetch attachments for
 * @param enabled Whether to automatically fetch attachments (defaults to true)
 */
export function useTaskAttachments(
    taskId: string | undefined,
    enabled = true
): UseTaskAttachmentsResult {
    const [attachments, setAttachments] = useState<Attachment[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<Error | null>(null)

    const fetchAttachments = async () => {
        if (!taskId) return

        setIsLoading(true)
        setError(null)

        try {
            const response = await fetch(`/api/tasks/${taskId}/attachments`)

            if (!response.ok) {
                throw new Error(`Failed to fetch attachments: ${response.statusText}`)
            }

            const data = await response.json()
            setAttachments(data.files || [])
        } catch (err) {
            console.error("Error fetching task attachments:", err)
            setError(err instanceof Error ? err : new Error(String(err)))
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (enabled && taskId) {
            fetchAttachments()
        }
    }, [taskId, enabled])

    return {
        attachments,
        isLoading,
        error,
        refetch: fetchAttachments
    }
} 